import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import 'css.gg/icons/css/spinner-two.css'

import colors from '../styles/colors'
import icons from '../config/icons'

import LinkFontAwesomeIcon from '../components/linkFontAwesomeIcon'
import ThemeToggleIcon from '../components/themeToggleIcon'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { GlobalStateContext } from '../context/GlobalContextProvider'

const WrapperDiv = styled.div`
  height: 82%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-rendering: optimizeLegibility;
  color: inherit;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.1;
`

const Name = styled.h1`
  margin-bottom: 1.45rem;
  font-size: 2.25rem;
`

const SubText = styled.h2`
  margin-bottom: 1.45rem;
  font-size: 1.62671rem;
`

const UnorderedList = styled.ul`
  margin-bottom: 1.45rem;
`

const ListItem = styled.li`
  display: inline-block;
  padding: 1.5rem;
  margin: 0 auto;
`

const IndexPage = () => {
  const state = useContext(GlobalStateContext)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [firstName, lastName] = data.site.siteMetadata.title.split(' ')
  const theme = colors[state.theme]

  return (
    <>
      <SEO
        title='Ameya Shenoy'
      />
      <ReactTooltip />
      <Layout>
        <WrapperDiv>

          <Name>
            {firstName}{' '}
            <div style={{
              display: 'inline-block',
              color: `${theme.accent}`
            }}>{lastName}</div>
          </Name>
          <SubText>{theme.subtext}</SubText>

          <ThemeToggleIcon />

          <UnorderedList>
            {icons.map(icon => {
              return <ListItem key={icon.title}>
                <LinkFontAwesomeIcon
                  accent={icon.accent}
                  iconName={icon.iconName}
                  link={icon.link}
                  title={icon.title}
                  iconSize={icon.iconSize}
                />
              </ListItem>
            })}
          </UnorderedList>

          <i
            className='gg-spinner-two'
            data-tip="Hustling, Improving"
            data-place="bottom"
            data-effect="solid"
            data-background-color="#212223"
            data-text-color="white"
            style={{
              color: `${theme.accent}`
            }}
          ></i>

        </WrapperDiv>
      </Layout>
    </>
  )
}

export default IndexPage
