import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import FontAwesomeIcon from '../components/fontAwesomeIcon.tsx'

import colors from '../styles/colors'

import { GlobalStateContext } from '../context/GlobalContextProvider'

const IconWrapper = styled.a`
  color: ${props => props.theme.text};
  &:hover {
    color: ${props => props.accent};
    transition: color 1s ease-out;
  }
  font-size: 1rem;
`

const LinkFontAwesomeIcon = (props) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  return (
    <IconWrapper
      theme={theme}
      accent={props.accent}
      href={props.link}
      aria-label={props.title}
      title={props.title}
      target="_blank"
      rel="noopener"
    >
      <FontAwesomeIcon iconName={props.iconName} iconSize={props.iconSize}/>{' '}
    </IconWrapper>
  )
}

LinkFontAwesomeIcon.propTypes = {
  iconName: PropTypes.node.isRequired,
  accent: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  iconSize: PropTypes.number
}

export default LinkFontAwesomeIcon
