const icons = [
  {
    accent: '#dfc517',
    iconName: 'github-alt',
    link: 'https://github.com/codingcoffee',
    title: 'GitHub',
    iconSize: 2
  }, {
    accent: '#03b381',
    iconName: 'matrix-org',
    link: 'https://matrix.to/#/@coding_coffee%3amatrix.org',
    title: 'Matrix',
    iconSize: 2
  }, {
    accent: '#bf4036',
    iconName: 'envelope-open-o',
    link: 'mailto:hello@codingcoffee.me',
    title: 'Email',
    iconSize: 2
  }, {
    accent: '#0077b5',
    iconName: 'linkedin',
    link: 'https://www.linkedin.com/in/codingcoffee',
    title: 'LinkedIn',
    iconSize: 2
  }
]

export default icons
